.react-international-phone-country-selector {
    display: inline-block;
}

.react-international-phone-input {
    width: 18.7vw;
}

.react-international-phone-input-container {
    margin-left: -0%;
}