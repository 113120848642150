.login-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-submit-btn {
    font-size: 15px !important;
    background: transparent;
    /* color: black; */
    color: white;
    /* background-color: #d4b962; */
    background-color: var(--secondary-background-color);
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border: none;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, .28);
    border-radius: 22px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.login-btn-submit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2% 0%;
}