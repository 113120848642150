.mainContainer {
    padding: 10px 24px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
}

.subHeader {
    padding: 2% 4%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.heading {
    text-align: center !important;
}

.dataRangePicker {
    padding: 2%;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4% 2%;
}