.mainContainer {
    padding: 10px 24px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
}

.tablesContainer {
    display: flex;
}

.table1Heading {
    margin-left: 1.6% !important;
    padding-top: 1.7%;
    padding-bottom: 1.2%;
    color: white;
}

.tableWidth {
    width: 98% !important;
    border-radius: 15px;
    margin-right: 1% !important;
    margin-left: 1% !important;
    margin-bottom: 1% !important;
    border: 1px solid #1f283e;
}

.lastTable {
    margin-bottom: 5%;
}

.toggle {
    margin-left: 1.2%;
    margin-top: 0.6%;
    margin-bottom: 1.4%;
}

.parent {
    /* display: flex; */
    /* Enables Flexbox */
}

.firstChild {
    margin-right: 20px;
    /* flex: 0 0 20%; */
    /* Takes up 30% of the parent div */
    /* More styles if needed */
}

.secondChild {
    /* flex: 0 0 80%; */
    /* Takes up 70% of the parent div */
    /* More styles if needed */
}

.paddingLeft {
    padding-left: '6%';
}

.headingStyle {
    /* background-color: #1a2035; */
    color: white !important;
    text-align: center;
}

.subHeader {
    display: flex;
    justify-content: space-between;
}