.mainContainer {
    padding: 10px 4px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
    margin-bottom: 2%;
    /* height: 833px; */
}

.subHeader {
    display: flex;
    justify-content: space-between;
    margin-left: 1%
}

.dateRangeContainer {
    margin: 2%;
    margin-top: 0%;
}