.sidebar {
    width: 250px;
    /* Width of the sidebar */
    height: 94vh;
    margin: 1% 0.6%;
    border-radius: 10px;
    /* Full height */
    /* position: fixed; */
    /* Fixed position */
    top: 0;
    /* Align to the top */
    left: 0;
    /* Align to the left */
    background-color: #1f283e;
    /* Background color of the sidebar */
    overflow-x: hidden;
    /* Prevent horizontal scroll */
    padding-top: 20px;
    /* Padding at the top */
}

.sidebar a {
    /* Padding for links */
    text-decoration: none;
    /* No underline */
    font-size: 18px;
    /* Font size */
    color: white;
    /* Text color */
    display: block;
    /* Display as block */
}

.sidebar a:hover {
    color: #f1f1f1;
    /* Color on hover */
}

.activeLink {
    width: 100%;
    background-color: #1a2035;
    /* Background color for active link */
}