.mainContainer {
    padding: 10px 4px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
    /* height: 833px; */
    padding-bottom: 30px;
}

.tablesContainer {
    display: flex;
}

.dateRangeContainer {
    margin: 2%;
    margin-top: 0%;
}

.parent {
    /* display: flex; */
    /* Enables Flexbox */
}

.firstChild {
    margin-right: 20px;
    width: 35%;
    overflow: scroll;
    overflow-x: hidden;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 3% 5%;
}

.secondChild {}

.paddingLeft {
    padding-left: '6%';
}

.headingStyle {
    background-color: #1a2035;
    color: white !important;
    text-align: center;
}

.subHeader {
    display: flex;
    justify-content: space-between;
    margin-left: 1%
}

.textBoxContainer {
    padding: 2% 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.countryDropdown {
    width: 100%;
    margin: 3% 0%;
    padding: 3% 0%;
}

.heading {
    text-align: center !important;
}