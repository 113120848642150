.popup-signup {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: black;
}

.popup-signup-inner {
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* // padding: 20px; */
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width: 30%;
}

.login-form {
    margin-bottom: 5%;
}

.signup-header {
    background-color: var(--primary-background-color);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 2% 3%;
}

.signup-stepper {
    padding: 5%;
}

.close-signup {
    cursor: pointer;
}

@media (max-width: 600px) {
    .popup-signup-inner {
        width: 80%;
    }
}