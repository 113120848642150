.closeButton {
    position: absolute;
    top: 10px;
    /* Adjust as needed */
    left: 10px;
    /* Adjust as needed */
    border: none;
    background: none;
    cursor: pointer;
}

.sidebar {
    width: 250px;
    /* Adjust the width as needed */
    height: 100vh;
    /* Full height of the viewport */
    position: fixed;
    /* Fixed position */
    top: 0;
    /* Align to the top */
    right: -250px;
    /* Start off-screen */
    background-color: #f4f4f4;
    /* Background color */
    transition: right 0.3s ease;
    /* Smooth transition for the 'right' property */
    /* Add any additional styling here */
}

.sidebar.open {
    right: 0;
    /* Move into view when open */
}


/* 
 */

.bet-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bet-main-container {
    background-color: var(--primary-background-color) !important;
    /* background-color: var(--primary-background-color); */
    /* color: white !important; */
    /* border: 1px solid rgba(255, 255, 255, .28); */
    border-bottom: 1px solid rgba(255, 255, 255, .28);
}

.bet-actions {
    display: flex;
    justify-content: flex-end;
}

.bet-description {}

.bet-team {
    margin: 0 !important;
    padding: 0 !important;
    margin: 10px 0;
    padding-bottom: 12% !important;
}

.bet-odd {
    display: flex;
    align-items: center;
}

.remove-bet {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.bet-amount {
    padding-top: 8%;
}

.match-result {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    /* color: #A0A0A0; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.match-detail {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.potential-payment {
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
    /* color: #A0A0A0; */
}

.payout-amount {
    color: black;
}

.bet-checkbox {
    display: flex;
    align-items: center;
}

.css-heg063-MuiTabs-flexContainer {
    justify-content: space-around !important;
}